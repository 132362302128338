<template>
  <KTCodePreview v-bind:title="'Danh sách các Form POSM đã điền'">
    <template v-slot:preview>
      <b-card no-body class="full-width">
        <div>
          <b-col cols="12" class="py-8 custom-card-body">
            <b-row class="pb-4">
              <b-col cols="8" class="d-flex align-items-center">
                <p class="mb-0"><strong>Tổng số</strong>: {{ totalResult }}</p>
              </b-col>
              <b-col cols="4" class="d-flex align-items-center">
                <span class="text-nowrap">Hiển thị:</span>
                <b-form-select
                  class="mr-2"
                  v-model="apiParams.perPage"
                  :options="perPageOptions"
                  size="sm"
                >
                </b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-table
                  bordered
                  :items="posmFormSubmissions"
                  :fields="fields"
                  empty-text="Chưa ai điền form POSM nào"
                  :per-page="apiParams.perPage"
                  class="table-vertical-center"
                >
                  <template #cell(campaign)="row">
                    <p>{{ row.item.campaign.name }}</p>
                    <p>
                      TG bắt đầu:
                      {{
                        formatDate(
                          new Date(row.item.campaign.startDate),
                          DATETIME_FORMAT,
                        )
                      }}
                    </p>
                    <p>
                      TG kết thúc:
                      {{
                        formatDate(
                          new Date(row.item.campaign.endDate),
                          DATETIME_FORMAT,
                        )
                      }}
                    </p>
                  </template>
                  <template #cell(store)="row">
                    <p>
                      Tên cửa hàng: {{ row.item.store.name }} ({{
                        row.item.store.shortName
                      }})
                    </p>
                    <p>{{ row.item.store.address }}</p>
                  </template>
                  <template #cell(createdAt)="row">
                    {{ formatDate(row.item.createdAt, DATETIME_FORMAT) }}
                  </template>
                  <template #cell(actions)="row">
                    <div class="d-flex justify-content-center">
                      <b-dropdown size="sm" id="dropdown-left" no-caret right>
                        <template slot="button-content">
                          <i
                            style="font-size: 1rem; padding-right: 0px"
                            class="flaticon2-settings"
                          ></i>
                        </template>
                        <b-dropdown-item
                          @click="
                            viewPOSMFormSubmissionInfoClickHandler(row.item)
                          "
                          v-if="checkPermission('POSM_FORM_SUBMISSION_VIEW')"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i style="font-size: 1rem" class="flaticon-eye"></i>
                            &nbsp; Chi tiết
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-model="apiParams.page"
                  :total-rows="totalResult"
                  :per-page="apiParams.perPage"
                  aria-controls="my-table-product"
                  align="right"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-col>
        </div>
      </b-card>
    </template>
  </KTCodePreview>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { fields } from '@/view/pages/posm/submission/table-setup';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { isArray } from 'lodash';
import { makeToastFaile } from '@/utils/common';
import { DATETIME_FORMAT, SUCCESS_RESPONSE_STATUS } from '@/utils/constants';
import { formatDate } from '@/utils/date';
import SelectOption from '@/view/pages/posm/models/SelectOption';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { checkPermission } from '@/utils/saveDataToLocal';

export default {
  components: {
    KTCodePreview,
  },
  data() {
    return {
      fields,
      DATETIME_FORMAT,
      totalResult: null,
      posmFormSubmissions: [],
      perPageOptions: [
        new SelectOption('5 dòng', 5),
        new SelectOption('10 dòng', 10),
        new SelectOption('20 dòng', 20),
        new SelectOption('30 dòng', 30),
        new SelectOption('40 dòng', 40),
        new SelectOption('50 dòng', 50),
      ],
      apiParams: {
        page: 1,
        perPage: 10,
      },
    };
  },
  watch: {
    apiParams: {
      handler() {
        this.fetchPOSMFormSubmissionInfo();
      },
      deep: true,
    },
  },
  methods: {
    checkPermission,
    formatDate,
    isArray,
    fetchPOSMFormSubmissionInfo(params) {
      ApiService.query(cmdUrl.PosmFormSubmission.root, {
        params: params ? params : { ...this.apiParams },
      })
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            this.totalResult = data.data.count;
            this.posmFormSubmissions = data.data.rows.map(
              (posmFormSubmission) => {
                return {
                  ...posmFormSubmission,
                  actions: posmFormSubmission.id,
                };
              },
            );
          }
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    viewPOSMFormSubmissionInfoClickHandler(rowData) {
      this.$router.push({
        name: 'posm-form-view-submission',
        params: {
          uuid: rowData.uuid,
        },
      });
    },
    routeCreatePosmForm() {
      this.$router.push({
        name: 'posm-form-create',
      });
    },
    routeImportExcel() {
      this.$router.push({
        name: 'posm-form-import-excel',
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách các Form POSM đã điền' },
    ]);
    this.fetchPOSMFormSubmissionInfo();
  },
};
</script>
