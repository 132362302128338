import TableHead from '@/view/pages/posm/campaign/models/TableHead';

export const fields = [
  // new TableHead('UUID', 'uuid'),
  new TableHead('Campaign', 'campaign'),
  new TableHead('Người khởi tạo form', 'employeeName'),
  new TableHead('Cửa hàng', 'store'),
  new TableHead('Thời gian điền', 'createdAt'),
  new TableHead('Chức năng', 'actions'),
];
